import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';

import * as webSocketActions from '../redux/actions/webSocketActions';
import * as serviceAddressActions from '../redux/actions/serviceAddressActions';

import Dashboard from '../components/Dashboard';

import serviceAddressMock from '../components/LandingMI/serviceAddressMock';

function MockDashboard({ actions, serviceAddress, siteConfig }) {
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [addressSearched, setAddressSearched] = useState(false);

  useEffect(() => {
    actions.serviceAddressUpdated({ ...serviceAddressMock, mock: true });
  }, []);

  return (
    <div>
      <Dashboard spaMode />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MockDashboard);
